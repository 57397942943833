html {
  font-size: 62.5%; /*default font size is 16px, so, 16 * 62.5 =10px =  1rem*/
  box-sizing: border-box; /*easier to manage boxes and padding, border and marging*/
}

body {
  margin: 0;
  /*height: 100vh; viewport height*/
  font-size: 1.6rem;
  /*background-color: #f8f9fd;*/
  font-family: Poppins, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

pre {
  display: block;
  white-space: pre;
  margin: 1em 0px;
}

hr {
  color: #000;
}

small {
  font-size: 12px;
}

#root {
  height: 100%;
}

.css-19kzrtu {
  padding: 0px;
}
.carousel.carousel-slider {
}
/*Header CSS starts*/

/*Header CSS starts*/

/*Layout*/
.canclePMargin {
  margin-top: 0;
  margin-bottom: 0;
}
.center-div {
  margin: auto;
  width: 50%;

  padding: 10px;
}

.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;

  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 300px;
  height: 300px;
}

.center-form {
  margin: auto;
  width: 50%;

  padding: 10px;
}

.centeringDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepageHeads {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: #2b2b2b;
}

.homepageHeadsCat {
  font-size: 22px;
  font-weight: 700;
  color: #2b2b2b;
}

.topHead {
  background-color: #dae3ea;
  height: 5vw;
}

.mainLogo {
  width: 135px;
}
.Header-bottom-main-div {
  /* The code that you need to copy */
  left: 0px;
  right: 0px;
  top: 0px;
  background-color: #444444;
  color: white;
  padding: 5px;
  text-align: center;
}

.Header-bottom-main-div span {
  color: #ffffff;
  text-align: center;
  align-items: center;
  padding-top: 10px;
  font-size: 14px;
  padding-bottom: 10px;
  width: 100%;
  background-color: #2b333b;
}

.mainPortionClass {
  margin-left: 18px;
  margin-right: 18px;
}
.css-19kzrtu {
  padding: 0px;
}
.grid-container {
  display: grid; /*responsble and flexible website*/
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-columns: 10fr; /*the website has only one column, there is no sidebar in left or right*/
  grid-template-rows: 3rem 10fr 5rem; /* header main footer*/
  height: 100%;
  max-width: 100%;
}

header {
  grid-area: header; /* indicates the header of grid-template-areas */
}

main {
  grid-area: main;
}
footer {
  grid-area: footer;
  background-color: #333;
  color: #ffffff;
  clear: left;
  clear: right;
}

.footer1 {
  grid-area: footer;
  padding: 3%;
  clear: left;
  clear: right;
  background-color: #333;
  color: #ffffff;
}

.flex-item-footer-link1 {
  font-size: 24px;
  margin-bottom: 18px;
}

.flex-item-footer-link {
  font-size: 15px;
  margin-bottom: 8px;
  cursor: pointer;
}

/*common */

.heading {
  font-size: 2.2rem;
}

h2 {
  font-size: 2.4rem;
  text-decoration: none;
  color: #333;
}
h3 {
  text-decoration: none;
  color: #333;
}
a {
  text-decoration: none;
}

a:hover {
  /*color: #DE3D3A;*/
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  margin-top: 1rem;
}

select {
  padding: 0.6rem;
  font-size: 1.2rem;
  border-radius: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  cursor: pointer;
}
.slide img {
  height: 450px;
  width: auto;
}
.sortSelect {
  font-size: 1.6rem;
  margin-left: 10px;
  border-radius: 10px;
}
.shopNameCSS {
  font-size: 18px;
  margin: 0px;
}
input,
textarea button {
  padding: 1rem;
  border: 2px solid #a4a4a4;
  border-radius: 0.8rem;
  font-size: 1.6rem;

  cursor: pointer;
}

.flex_social_btn_container {
  display: flex;
  flex-flow: row nowrap;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 500px;
}

.social_btn {
  margin-top: 12px;
  margin-bottom: 10px;
  margin-left: 12px;
  font-size: 16px;
  width: 250px;
  padding: 13px;
  background-color: #ffffff;
  border-color: #ffffff;
}

.categories {
  background-color: #ffffff;
}

.cat_div {
  display: flex;
  flex-wrap: wrap; /*If there is no enough space it will go to seprate line*/
  justify-content: space-between; /*maximus space among the items*/
  align-items: center;
}

.cat_div_div {
  background-color: #ffffff;
  padding: 30px;
  height: 70px;
  width: 227px;
}

.cat_div_div a {
  font-size: 1.5rem;
}

.shoppingMalls {
  color: rgb(55 55 55);
  font-size: 24px;
}

.link_tabs {
  color: #000;
  text-decoration: underline;
  font-size: 17px;
  margin-left: 12px;
  margin-top: 0px;
}

.btn_tabs {
  background-color: #de3d3a;
  width: 18%;
  padding-top: 10px;
  border-radius: 9px;
  border: 2px solid #de3d3a;
  padding-bottom: 10px;
  color: #ffffff;
}

.btn_forgetpass {
  background-color: #de3d3a;
  width: 150px;
  padding-top: 10px;
  border-radius: 9px;
  border: 2px solid #de3d3a;
  padding-bottom: 10px;
  color: #ffffff;
}

.social_btn:hover {
  border-color: #ffffff;
}

button {
  background-color: #f8f8f8;
  cursor: pointer;
}

button.small {
  font-size: 1.2rem;
}

input:hover,
select:hover,
textarea:hover,
button:hover {
  border: 0.1rem #a9a9a9 solid;
}

button.primary {
  background-color: #f0c040;
  padding: 0.7rem;
}

button.danger {
  background-color: #de3d3a;
  padding-top: 12.7px;
  padding-bottom: 10px;
  padding-right: 20px;
  color: #ffffff;
  border: 2px solid #de3d3a;
}

.Tabs_Head {
  font-size: 27px;
  margin-left: 8px;
}

#simple-tab-0 {
  border-color: #ffffff;
  color: #2b333b;
  font-size: 12px;
}

#simple-tab-1 {
  border-color: #ffffff;
  color: #2b333b;
  font-size: 12px;
}

.dialoageInput {
  width: 250px;
}

select.danger {
  background-color: #de3d3a;
  padding: 10px;
  color: #ffffff;
  border: 2px solid #de3d3a;
}
.radius_btn {
  border-radius: 8px;
  width: 200px;
  padding: 20px;
  margin-bottom: 15px;
}

button.dark {
  background-color: #24313b;
  color: #ffffff;
  padding: 1rem 6rem;
}

button.white {
  background-color: #f8f8f8;
  padding: 1rem 6rem;
}

button.block {
  width: 100%;
}
/*Header*/
header a {
  color: #c93232;
  padding: 1rem;
}
.flex-brand {
  display: flex;
  flex-flow: row wrap;
}

.flex-brand-item {
  text-align: center;
  align-items: center;
  margin-top: 9px;
}

a.brand {
  /*a that has brand class*/
  color: #ffffff; /* 053568*/
  font-size: 34px;
  font-weight: 600;
}

.headerBtn {
  border: none;
  background-color: #d1deeb;
  color: #333;
  margin-right: 30px;
  border-radius: 10px;
  width: 120px;
  padding-top: 11px;
  padding-bottom: 11px;
  text-align: center;
}
.custom-search {
  position: relative;
  width: 550px;
}
.custom-search-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding: 10px 100px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
}
.custom-search-botton {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  background: #053568;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 10px;
  border-radius: 100px;
  z-index: 2;
}
.headerBtn1 {
  border: none;
  background-color: #d1deeb;
  color: #333;
  margin-right: 30px;
  border-radius: 10px;
  width: 120px;
  padding-top: 11px;
  padding-bottom: 11px;
  text-align: center;
}

.headerIcons {
  color: #ffffff;
  font-size: 32px;
}

.badge {
  background-color: #f02020;
  color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
}

.rowProdTop {
  display: flex;
  /*justify-content: space-between; maximus space among the items*/
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
}

.row {
  /*set item next to each other*/
  display: flex;
  flex-wrap: wrap; /*If there is no enough space it will go to seprate line*/
  justify-content: space-between; /*maximus space among the items*/
  align-items: center;
  margin: 0px;
}

.ProductRow {
  display: flex;
  flex-wrap: wrap; /*If there is no enough space it will go to seprate line*/
  justify-content: space-between; /*aximus space among the items*/
  align-items: center;
  margin: 0px;
}

.ProductRow.center {
  justify-content: center;
}
.dokanListrow {
  display: flex;
  flex-wrap: wrap; /*If there is no enough space it will go to seprate line*/
  justify-content: space-between; /*maximus space among the items*/
  align-items: center;
  margin: 0px;
}

.dokanListrow.center {
  justify-content: center;
}

.rowFlexStart {
  /*set item next to each other*/
  display: flex;
  flex-wrap: wrap; /*If there is no enough space it will go to seprate line*/
  justify-content: flex-start; /*maximus space among the items*/
  align-items: center;
}

.row1 {
  /*set item next to each other*/
  display: flex;
  flex-wrap: wrap; /*If there is no enough space it will go to seprate line*/
  justify-content: space-between; /*maximus space among the items*/
  align-items: center;
  padding-top: 10px;
  background-color: #053568;
  padding-bottom: 10px;
}
.mobile-dropdown {
  color: #333;
  font-size: 14px;
  padding-bottom: 0px;
}

.mobile-dropdown-main {
  color: #333;
  font-size: 18px;
  align-items: center;
  padding-bottom: 0px;
}
.mobile-dropdown:hover {
  color: #a02020;
}
.rowFlash {
  display: flex;
  overflow-x: scroll;
  padding: 9px;
  background-color: teal;
  overflow-x: scroll;
}

.row.top {
  align-items: flex-start;

  border-radius: 1.3rem;
}

.row.start {
  justify-content: flex-start;
}

.col-1 {
  margin-left: 30px;
  background-color: #c8e3ff;
  padding: 30px;
}

.col-1-side {
  flex: 0.3 0 0;
}
.col-1-displayed {
  margin-left: 20px;
}
.bolded {
  font-size: 20px;
  color: #ffffff;
}

.col-2 {
  flex: 1 1 10rem; /*2 1 50 */
  margin: 30px;
  border: 1px solid #dae3ea;
  margin-left: 10vw;
}

.col-2-revised {
  flex: 1 1 10rem; /*2 1 50 */
  /*margin: 30px;*/
  border: 1px solid #dae3ea;
}

.cart {
  margin-left: 20px;
  margin-right: 20px;
}

.col-3 {
  flex: 32 2 12rem;
}

.min-30 {
}

.min-30 a {
  color: #f02020;
}

.rightsideHeader {
  display: flex;
  flex-wrap: wrap; /*If there is no enough space it will go to seprate line*/
  flex-direction: row;
  justify-content: space-around; /*maximus space among the items*/
  align-items: center;
}

.row.center {
  justify-content: center;
}

.row.center1 {
  justify-content: flex-start;
}

.p-1 {
  padding: 1rem;
}

/*image
img {
  border-radius: 1rem;
}
*/
img.small {
  max-width: 5rem;
  width: 100%;
}

img.description {
  max-width: 80rem;
  margin: auto;
  width: 100%;
}

.description {
  display: flex;
  top: 50%;
  left: 50%;
}

.imageCarousel {
  width: auto; /* width: 100%;*/
}
img.medium {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

img.medium1 {
  display: block;
  width: 100%;
  object-fit: contain;
  height: 300px;
  margin-top: 4px;
  margin-bottom: 0px;
  /*padding: 4px 4px; */
  border-radius: 0.5rem;
  /*
    object-fit: cover;
    margin: 10px auto;
    */
}

img.medium2 {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

img.dokanHomeImg {
  height: 220px;
  width: 100%;
  object-fit: cover;
  /*
   
 
 
  */
}

img.sellerLogo {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  padding: 10px;
  margin-right: 20px;
  background-color: #333;
  object-fit: cover;
  margin-top: 1px;
}

img.cat_home_width {
  width: 190px;
  object-fit: cover;
}

img.large {
  /*width: auto;*/
  object-fit: contain;
}

.imageCarousel1:hover {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2);
  transition-duration: 0.5s;
  transition-timing-function: linear;
}

.imageCarousel2:hover {
  -ms-transform: scale(1.6); /* IE 9 */
  -webkit-transform: scale(1.6); /* Safari 3-8 */
  transform: scale(1.6);
  transition-duration: 0.5s;
  transition-timing-function: linear;
}

/*card*/
.card {
  width: 232px;
  background-color: #fff;
  margin: 1rem;
  border-radius: 1rem;
}

.card:hover {
  border: 1px solid #497570;
}

.DokanListcard {
  width: 620px;
  height: 270px;
  margin: 5px;
  border-radius: 3px;
  /*box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);*/
  background-color: #444444;
}

.DokanListcard:hover {
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-place-order {
  padding: 30px;
  border: 0.1rem #ffffff solid;
  background-color: #fefefe;
  margin-top: 1rem;
  border-radius: 1rem;
}

.cardShopType {
  width: 232px;
  border: 0.1rem #ffffff solid;
  background-color: #fefefe;
  margin: 1rem;
  border-radius: 1rem;
}

.cardProd {
  width: 200px;
  /*box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.2);   background-color: #fcfcfc;*/
  border: 1px solid #f3f3f3;
  border-radius: 0.5rem;
  margin: 4px;
}

.cardSeller {
  width: 272px;
  border: 0.1rem #ffffff solid;
  background-color: #ffffff;
  border-radius: 1rem;
  margin: 1rem;
}

.card1 {
  width: 287px;
  border: 0.1rem #ffffff solid;
  background-color: #ffffff;
  border-radius: 0.5rem;
  margin: 1rem;
  padding: 1rem;
}

.card-body {
  padding: 0.5rem 1rem 1rem 0.5rem;
}

.price {
  color: #333;
  font-size: 20px;
  font-weight: 700;
}

.DokanName {
  color: #fff;
  font-size: 18px;
  height: 10px;
}
.DokanName:hover {
  color: #fff;
}

.priceHomepage {
  font-size: 1.7rem;
}

/*rating*/
.rating span {
  color: #24313b;
  margin: 0.1rem;
  font-size: 14px;
  font-weight: 300;
}

/*alert*/
.loadinh {
  display: block !important;
}
.success {
  color: #20a020;
}

.error {
  color: #a02020;
}

.danger {
  color: #a02020;
}

/*product classes*/
.name {
  font-size: 2rem;
  text-decoration: none;
  color: #333;
  font-size: 27px;
  margin-right: 12px;
}

.singleProductPageImgs {
  max-width: 600px; /*  max-width: 600px;*/
  max-height: 900px;
}
.float-child1 {
  width: 20%;
  float: left;
  margin-top: 20px;
}

.float-child2 {
  width: 78%;
  float: right;
}

.homeCatImg {
  height: 320px;
  width: 299px;
  margin-left: auto;
  margin-right: auto;
}
.rightsideSingleProduct {
  background-color: #2b333b;
  color: #ffffff;
  border-radius: 1rem;
  width: 110%;
  padding: 30px;
}
.nameHomepage {
  color: #333;
  /*font-weight: 600 !important;*/
  font-size: 16px;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 0px;
}

.nameHomepage:hover {
  text-decoration: underline;
}

.nameHomepage1 {
  color: #333;
  font-weight: 600 !important;
  font-size: 12px;
}

.productDescription {
  width: 100%;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.productDescription::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.productDescription {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.type {
  color: #333;
  font-size: 1.6rem;
}

.TypeDisable {
  color: #333;
  font-size: 14px;
}
hr {
  color: #797979;
}
.TypeDisableReal {
  color: #797979;
  font-size: 14px;
}

.HomepageProductButtonCart {
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dae3ea;
  cursor: pointer;
  float: right;
}

.dropdownProductScreen {
  padding: 1rem;
}

.addtoCardbtn {
  background-color: #de3d3a;
  width: 38%;
  padding-top: 10px;
  border-radius: 9px;
  border: 2px solid #de3d3a;
  padding-bottom: 10px;
  color: #ffffff;
}

.addtoWishlistbtn {
  background-color: #d1deeb;
  width: 38%;
  padding-top: 10px;
  border-radius: 9px;
  border: 2px solid #d1deeb;
  padding-bottom: 10px;
  color: #333;
}

.addReviewbtn {
  background-color: #de3d3a;
  width: 18%;
  padding-top: 10px;
  border-radius: 9px;
  border: 2px solid #de3d3a;
  padding-bottom: 10px;
  color: #ffffff;
}

/*alert*/
.danger {
  color: #a02020;
}
.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0;
}

.alert-danger1 {
  padding: 10rem;
  color: #a02020;
  background-color: #ffe0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}

/*FORM*/
* Form */ .form {
  max-width: 60rem;
  margin: 0 auto;
}
.form > div {
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 1rem 1rem;
}
.form label {
  margin: 1rem 0;
}

.InputFlexRow {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.InputFlexColumn {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
}

.mlOfProductEditScreen {
  margin-left: 10px;
}

/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropDownLink {
  color: #000000;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 12rem;
  padding: 1rem;
  z-index: 1;
  background-color: #ffffff;
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}

/* Checkout Steps */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #f08000;
  color: #f08000;
}

/* Table */
.table {
  width: 100%;

  border-collapse: collapse;
}
.responsiveTable {
  overflow-x: auto;
}
.table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}
.table td,
.table th {
  text-align: left;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
}
.table button {
  margin: 0 0.2rem;
}

/* Carousel */
.carousel .slide img {
  height: auto;
  margin-top: 0 auto;
}

.carousel .thumbs-wrapper {
  overflow: auto;
}

.carousel .thumb {
  height: 100px;
}
.carousel .slide1 img {
  height: auto;
  margin-top: 0 auto;
}

.card-under-carousel {
  display: flex;
  flex-flow: row wrap;
  width: 271px;
}

.css-bbgsq2-MuiPaper-root-MuiCard-root {
  width: 271px;
  margin-left: 10rem;
  margin-right: 10rem;
  margin-top: 3rem;
}
/* Search */
.search {
  text-align: center;
  width: 100%;
}

.search-flex-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.search-flex-item {
  margin: 0 auto;
}
.search button {
  border-radius: 0 1rem 1rem 0rem;
  border-right: none;
  color: #ffffff;
  margin: 0;
  border: 2px solid #053568;
  background-color: #053568;
  padding-top: 12.5px;
  padding-bottom: 10.5px;
}
.search input {
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: none;
  width: 70%;
  border: 2px solid #c5d2cd;
  background-color: #ffffff;
  margin-left: 0.5rem;
}

.searchFormDiv {
  margin-top: 0px;
}
.btn-search {
  margin-left: -50px;
}
.searchFormDiv input {
  padding-left: 100px;
  padding-right: 100px;
}
/* Aside */
aside {
  position: fixed;
  width: 30rem;
  height: 100%;
  background-color: #efefef;
  z-index: 1000;
  transform: translateX(-30rem);
  transition: all 0.5s;
}

.hidethisSearchAfter768 {
  margin: auto;
  width: 50%;
}
aside.open {
  transform: translateX(0);
  background-color: #ffffff;
  overflow: scroll;
}

aside.open:-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

button.open-sidebar {
  font-size: 3rem;
  padding: 0.2rem 0.5rem;
  margin: 0 1rem 0 4rem;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}
button.open-sidebar:hover {
  border-color: #ffffff;
}
aside ul {
  padding: 0;
  list-style: none;
}

aside li {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
button.close-sidebar {
  padding: 0.3rem 0.8rem;
}
.active {
  font-weight: bold;
  color: #de3d3a;
}

.active1 {
  font-weight: bold;
  color: rgb(222, 61, 58);
}

.noActive {
  color: #333;
}

/* Pagination */
.pagination a {
  color: #333;
  background-color: #ffffff;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #061c33 solid;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pagination a.active {
  font-weight: bold;
  background-color: #485993;
  color: #ffffff;
}

/*Marchant account setup css starts*/
.marchant_page_common_css {
  margin-left: 40px;
}
.Marchannt_reg_Input {
  background-color: #ffffff;
  width: 40%;
}

.btn_marchant_account {
  background-color: #de3d3a;
  width: 120px; /*was 18%*/
  padding-top: 10px;
  border-radius: 9px;
  border: 2px solid #de3d3a;
  padding-bottom: 10px;
  color: #ffffff;
}

.btn_see_more_product {
  color: #5f5f5f;
}

.btn_addtocard_home {
  background-color: #e9e9e9;
  width: 100%;
  padding-top: 10px;
  border-radius: 9px;
  border: 2px solid #e9e9e9;
  padding-bottom: 10px;
  color: #515151;
}

.btn_addtocard_home:hover {
  background-color: #de3d3a;
  color: #fff;
}

.sidebarfont {
  color: #333;
  font-size: 1.3rem;
}

/* verification page */
#form {
  display: flex;
  flex-flow: row nowrap;
  max-width: 240px;
}
.validation_input {
  margin: 0 5px;
  text-align: center;
  width: 200px;
  border: solid 1px #ccc;
  box-shadow: 0 0 5px #ccc inset;
  outline: none;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
}

.flexNoWrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.ShoppingMallIntro {
  margin-right: 30px;
}

.grid-container-mobile-header {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  background-color: #053568;
  margin-top: 10px;
}

.sellerMobileDiv {
  display: grid;
  grid-template-columns: auto;
}
.grid-item-mobile-1 {
  text-align: left;
}
.grid-item-mobile-2 {
  text-align: center;
}

.grid-item-mobile-3 {
  text-align: end;
  font-size: 35px;
}
/*verification end*/
/*Marchant account setup css ends*/
@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
  .search input {
    border-radius: 0.5rem 0 0 0.5rem;
    border-right: none;
    padding-right: 5rem;
    background-color: #ffffff;
    margin-left: 0.5rem;
  }
  .radius_btn {
    border-radius: 8px;
    width: 200px;
  }

  .danger {
    padding-top: 10px;
  }
  .search-flex-container {
    margin-left: 5px;
    margin-right: 5px;
  }
  .carousel-main {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .flex-brand {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /*Marchant account setup css starts*/
  .marchant_page_common_css {
    margin-left: 10px;
  }
  .Marchannt_reg_Input {
    background-color: #ffffff;
    width: 90%;
  }

  .btn_marchant_account {
    background-color: #de3d3a;
    width: 120px;
    padding-top: 10px;
    border-radius: 9px;
    border: 2px solid #de3d3a;
    padding-bottom: 10px;
    color: #ffffff;
  }
  /*Marchant account setup css ends*/
}
@media only screen and (max-width: 1025px) {
  .cat_div_div {
    width: auto;
  }

  .cat_div_div a {
    font-size: 1.2rem;
    line-height: 1px;
  }

  .card {
    width: 202px;
  }
  #zoom {
    width: 100%;
    height: 100%;
    transform-origin: 0px 0px;
    transform: scale(1) translate(0px, 0px);
    cursor: grab;
  }
  .cardProd {
    /* width: auto;*/
  }
  .cardSeller {
    width: 220px;
  }
  .DokanName {
    font-size: 16px;
    height: 10px;
  }
}
@media only screen and (min-width: 865px) {
  .col-2 {
    flex: 1 1 15rem; /*2 1 50 */
    margin: 30px;
    border: 1px solid #dae3ea;
    margin-left: 10vw;
  }
  .col-2-revised {
    flex: 1 1 15rem; /*2 1 50 */
    /*border: 1px solid #DAE3EA;*/
  }
}
@media only screen and (min-width: 900px) {
  .grid-container-mobile-header {
    display: none;
  }
}
@media only screen and (max-width: 899px) {
  .row1 {
    display: none;
    background-image: linear-gradient(to right, #39a1a6, #39a1a6);
    color: white;
  }
}
@media only screen and (max-width: 769px) {
  .rowProdTop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /*maximus space among the items*/
    margin: 0px;
  }

  .center-div {
    margin: auto;
    width: 100%;

    padding: 0px;
  }

  .center-form {
    margin: auto;
    width: 100%;

    padding: 0px;
  }

  img.medium2 {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  .rowFlexStart {
    display: grid;
    grid-template-columns: auto auto;
  }
  .ProductRow {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .DokanListcard {
    width: 175px;
  }
  .dokanListrow {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  img.medium1 {
    height: 250px; /*190px*/
    object-fit: contain;
  }
  .row1 {
    display: none;
    background-image: linear-gradient(to right, #39a1a6, #39a1a6);
    color: white;
  }
  .headerBtn {
  }
  .rightsideHeader {
    align-items: center;
    justify-content: center;
  }
  .cardProd {
    width: auto;
  }
  .card {
    width: 30%;
  }
  .cardSeller {
    width: 142px;
  }

  .cat_div_div {
    width: auto;
  }

  .float-child1 {
    display: none;
  }

  .float-child2 {
    width: 100%;
  }
  .homeCatImg {
    height: 320px;
    width: 149px;
    margin-left: auto;
    margin-right: auto;
  }

  .ShoppingMallImage img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    object-fit: cover;
  }
  .col-1 {
    display: none;
    margin-left: 20px;
  }
  select,
  .sortSelect {
    font-size: 1.23rem;
    width: 17rem;
    margin-left: 10px;
    border: 2px solid #ffffff;
  }
  img.cat_home_width {
    width: 180px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 600px) {
  .rowProdTop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /*maximus space among the items*/
    margin: 0px;
  }
  /*Big smartphones [426px -> 600px]*/
  .mainPortionClass {
    margin-left: 0px;
    margin-right: 0px;
  }
  .cat_div {
    width: 50px;
  }
  .card {
    width: 167px;
  }

  .cardSeller {
    width: 167px;
  }
  .DokanName {
    font-size: 16px;
    height: 10px;
  }
  .nameHomepage {
    color: #333;
    font-weight: 600 !important;
    font-size: 16px;
    align-items: center;
  }
  .imageCarousel {
    width: auto;
  }
  .headerIcons {
    background-color: #ffffff;
    border: 3px solid #053568;
  }
  .headerBtn {
  }
  .mlOfProductEditScreen {
    margin-left: 0px;
    margin-top: 20px;
  }
  .search input select {
    border-radius: 0.5rem 0 0 0.5rem;
    border-right: none;
    padding-right: 5rem;
    background-color: #ffffff;
    margin-left: 0.5rem;
  }
  .radius_btn {
    border-radius: 8px;
    width: 200px;
    margin-top: 13px;
  }
  .search-flex-container {
    margin-left: 5px;
    margin-right: 5px;
  }
  .carousel-main {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  /*Marchant account setup css starts*/
  .marchant_page_common_css {
    margin-left: 10px;
  }
  .Marchannt_reg_Input {
    background-color: #ffffff;
    width: 90%;
  }

  .btn_marchant_account {
    background-color: #de3d3a;
    width: 120px;
    padding-top: 10px;
    border-radius: 9px;
    border: 2px solid #de3d3a;
    padding-bottom: 10px;
    color: #ffffff;
  }
  .shoppingMalls {
    color: rgb(55 55 55);
    font-size: 18px;
  }
  .homepageHeads {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #2b2b2b;
  }
  /*Marchant account setup css ends*/
}
@media only screen and (max-width: 425px) {
  .mainLogo {
    width: 125px;
  }
  .shoppingMalls {
    color: rgb(55 55 55);
    font-size: 18px;
  }
  .homepageHeads {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #2b2b2b;
  }
  .cardProd {
    width: auto;
  }
  img.medium2 {
    border-radius: 50%;
    width: 135px;
    height: 135px;
    object-fit: cover;
  }
  .ProductRow {
    display: grid;
    grid-template-columns: auto auto;
  }
  img.medium1 {
    /*height: 240px;*/
    object-fit: contain;
  }
  .dokanListrow {
    display: grid;
    grid-template-columns: auto auto;
  }
  .footerLogo {
    margin-top: 10px;
    margin-bottom: 100px;
  }

  .headerIcons {
    margin-right: 12vw;
    display: none;
  }
  .header a {
    padding: 0.2rem;
  }
  .rightsideHeader {
    justify-content: inherit;
    margin-left: 4.56vw;
  }
  .slide img {
    height: auto;
    width: auto;
  }
  .card {
    /*width: 38%;*/
  }
  .cardShopType {
    width: 143px;
  }
  .cardSeller {
    width: 145px;
  }
  img.sellerLogo {
    border-radius: 50%;
    width: 110px;
    margin-right: 20px;
    height: 110px;
    object-fit: cover;
  }

  select,
  .sortSelect {
    font-size: 1.5rem;
    width: 19.5rem;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;
  }
  .thumb.selected {
    height: 100px;
  }

  .thumb.selected img {
    height: 99px;
  }
  aside.open {
    width: 100%;
  }
  .row1 {
    display: none;
    background-image: linear-gradient(to right, #39a1a6, #39a1a6);
    color: white;
  }

  a.brand {
    color: #ffffff;
  }

  .searchFormDiv {
    color: white;
    margin-top: 15px;
  }
  .Header-bottom-main-div {
    font-size: 12px;
  }
  .search-flex-container p {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mainPortionClass {
    margin-left: 0px;
    margin-right: 0px;
  }
  .imageCarousel {
    width: auto;
  }
  .headerIcons {
    color: #ffffff;
  }
  .headerBtn {
  }

  .search input {
    border-radius: 1.5rem 0 0 1.5rem;
    border-right: none;
    padding-right: 0rem;
    background-color: #ffffff;
    margin-left: 0.5rem;
  }

  button.danger {
    background-color: #de3d3a;
    padding-top: 11px;
  }

  .radius_btn {
    border-radius: 8px;
    width: 200px;
    padding-top: 11px;
    padding-bottom: 10px;
  }

  .search-flex-container {
    margin-left: 5px;
    margin-right: 5px;
  }
  .carousel-main {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .selected img {
    object-fit: fill;
  }

  .flex-brand {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rightsideHeader {
    display: flex;
    justify-content: center;
  }
  /*Marchant account setup css starts*/
  .marchant_page_common_css {
    margin-left: 10px;
  }
  .Marchannt_reg_Input {
    background-color: #ffffff;
    width: 90%;
  }

  .btn_marchant_account {
    background-color: #de3d3a;
    width: 80px;
    padding-top: 10px;
    border-radius: 9px;
    border: 2px solid #de3d3a;
    padding-bottom: 10px;
    color: #ffffff;
  }

  .headerBtn1 {
    border: none;
    background-color: #d1deeb;
    color: #333;
    margin-right: 30px;
    border-radius: 10px;
    width: 80px;
    padding-top: 11px;
    padding-bottom: 11px;
    text-align: center;
  }
  /*Marchant account setup css ends*/

  .cat_div_div {
    width: auto;
  }

  .cat_div_div a {
    font-size: 1.2rem;
    line-height: 1px;
  }
  .DokanName {
    font-size: 12px;
    height: 10px;
  }
  .nameHomepage {
    color: #333;
    font-weight: 600 !important;
  }

  img.cat_home_width {
    width: 130px;
    object-fit: cover;
  }
  .price {
    font-size: 14px;
  }
}
@media only screen and (max-width: 375px) {
  .shopNameCSS {
    font-size: 16px;
    margin: 0px;
  }
  .cardShopType {
    width: 38%;
  }
  select,
  .sortSelect {
    font-size: 1.25rem;
    width: 17rem;
    margin-left: 10px;
    border-radius: 10px;
  }
  .card {
    width: 138px;
  }
  .cardProd {
    width: auto;
  }
  .cardSeller {
    width: 128px;
  }
  .cat_div_div {
    width: auto;
  }

  .cat_div_div a {
    font-size: 1.2rem;
    line-height: 1px;
  }
  img.medium1 {
    height: 140px;
    object-fit: contain;
  }
  img.cat_home_width {
    width: 100px;
    object-fit: cover;
  }
  .price {
    font-size: 14px;
  }
}

@media only screen and (max-width: 325px) {
  .shopNameCSS {
    font-size: 14px;
    margin: 0px;
  }
  img.medium2 {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  .cardShopType {
    width: 111px;
  }

  img.cat_home_width {
    width: 80px;
    object-fit: cover;
  }
  .price {
    font-size: 14px;
  }
  select,
  .sortSelect {
    font-size: 1rem;
    width: 14rem;
    margin-left: 10px;
    border-radius: 10px;
  }
  .mainPortionClass {
    margin-left: 0px;
    margin-right: 0px;
  }
  .imageCarousel {
    width: auto;
  }

  .cat_div_div {
    width: auto;
  }

  .cat_div_div a {
    font-size: 1.2rem;
    line-height: 1px;
  }

  .card {
    width: 39%;
  }
  .cardProd {
    width: auto;
  }
  .cardSeller {
    width: 102px;
  }
  .DokanName {
    font-size: 11px;
    height: 10px;
  }
  .nameHomepage {
    color: #333;
    font-weight: 600 !important;
  }
}

@media only screen and (min-width: 769px) {
  .selectDisplayNone {
    display: none;
  }
  .SearchMain {
    padding-left: 20px;
  }
}
